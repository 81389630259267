import React, { createContext, useEffect, useState } from "react";
import { use100vh } from "react-div-100vh";

export const Context = createContext({
  height: "0",
});

const FullHeight = (props) => {
  const [height, setHeight] = useState();
  const fullHeight = use100vh();

  useEffect(() => {
    setHeight(fullHeight);
  }, [fullHeight]);

  return (
    <Context.Provider value={{ height }}>{props.children}</Context.Provider>
  );
};

export default FullHeight;
